.card {
  position: relative;
  display: block;
  z-index: 1;

  border: 1px solid #CCC;
  border-radius: 1em;

  color: inherit;
  background: #FFF;

  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;

  margin-bottom: 2em;

  &__image {
    background-size: cover;
  }

  &__content {
    padding: 1em;
  }

  &__source + &__date {
    &:before {
      content: "• ";
    }
  }

  &__length {
    float: right;
  }

  &__title {
    
  }

  &__prompt {
    color: $link-color;
    @extend .body-link;
  }

  @media (max-width: 767px) {
    &__image {
      height: 100px;

      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
    }
  }

  @media (min-width: 768px) {
    &__image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 50%;

      border-top-left-radius: 1em;
      border-bottom-left-radius: 1em;
    }

    &__image + &__content {
      width: 50%;
      margin-left: 50%;
      padding-left: 2em;
    }
  }
}