// Header text feature
.call-out {
  @extend %padding-regular;
  display: inline-block;
  width: 100%;
  background-color:$header-desc-background-color;
  background-size: cover;
  font-size: 1.2em;
  text-align: center;
  color: $header-desc-text-color;
}
.call-out p:last-child {
  margin-bottom: 0;
}
.call-out a {
  color: $header-desc-link-color
}
// Post listing
.posts {
  overflow: auto;

  &--would {
    height: 60%;
  }

  &--could {
    height: 40%;
  }

  .post-teaser {
    @extend %padding-regular;
    padding-top: 1em;
    padding-bottom: 1em;
    width: 100%;
    margin-bottom: 0;
    display: inline-block;
    background-size: $feature-image-size;
    border-bottom: 1px solid $border-color;
    text-align: center;
    text-decoration: underline;
    p a {
      @extend .body-link;
    }
  }
  .excerpt {
    margin-top: 1em;
  }
}
// Pagination
.pagination .button {
  margin: 0 1.5em;
  i {
    vertical-align: middle;
  }
}
.pagination {
  padding: $padding-small $padding-large 0 $padding-large;
  text-align: center;
  @media (max-width: 600px) {
    padding: $padding-small;
  }
}

.post-peek {
  a {
    text-decoration: underline;
  }

  &--would {
    background: #111;

    a {
      color: #DDD;
    }
  }

  &--could {
    background: #EEE !important;
  }
}
