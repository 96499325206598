.site-header {
  background: $brand-color;
  border-bottom: 1px solid $border-color;
  display: inline-block;
  float: left;
  width: 100%;

  height: 5rem;
  transition: all 0.2s ease-in-out;

  .site-title {
    font-size: 1.75em;
    line-height: 5rem;

    transition: all 0.2s ease-in-out;
  }

  &--fixed {
    position: fixed;
  }

  &.-scrolled {
    height: 2rem;

    .site-title {
      font-size: 1.25em;
      line-height: 2rem;
    }
  }

  a {
    color: $header-link-color;
  }

  .avatar {
    height: 2em;
    width: 2em;
    float: left;
    margin-top: -3px;
    border-radius: 0.2em;
    margin-right: 1em;
  }

  .site-title {
    font-weight: bold;
    text-align: center;
  }

  & + div {
    padding-top: 5rem;
  }

  &.-scrolled + div {
    padding-top: 3rem;
  }
}

