.submit {
  text-align: center;

  form {
    max-width: 600px;
    margin: 0 auto;

    input, textarea {
      width: 100%;
      margin-bottom: 1em;
      padding: 0.25em;

      border: 1px solid #CCC;
    }

    > input {
      display: none;
    }

    button {
      display: block;
      width: 100%;
      padding: 0.5em;

      border-radius: 0.35em;

      color: #FFF;
      background: $link-color;

      font-weight: bold;
    }
  }
}

.thanks {
  text-align: center;
}