.embed {
  text-align: center;

  &--twitter {
    .embed__frame {
      > * {
        margin: 0 auto;
      }
    }
  }

  &--youtube {
    border: 1px solid #CCC;
    border-radius: 1em;

    color: inherit;
    background: #FFF;

    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;

    .embed__frame {
      position: relative;
      height: 385px;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .embed__meta {
      padding: 1em;
    }

    .embed__source, .embed__length {
      color: #666;
      font-size: 0.85em;
    }

    .embed__length {
      float: right;
    }
  }
}