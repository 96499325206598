.tag-title {
  color: $tags-color;
  margin-bottom: 0;
}
.tagged-post {
  .title {
    margin-top: 0;
    margin-bottom: 0;
  }
}
