.site-footer {
  @extend %padding-regular;
  display: inline-block;
  text-align: center;
  width: 100%;
  color: lighten($text-color, 30%);
  font-size: 0.9em;

  .site-nav {
    text-align: center;

    a {
      color: inherit;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      line-height: 1.5;
    }
    li {
      display: inline-block;
      margin-right: 1em;
      vertical-align: middle;
      &:last-of-type {
        margin-right: 0;
      }
    }
    @media (max-width: 1100px) {
      ul {
        display: inline-block;
        float: left;
        padding-top: $padding-small;
        width: 100%;
      }
    }
  }

    /* --- Search form --- */
    form {
        border: 0;
        outline: 0;

        *:focus {
            outline: none;
        }

        input{
            &[type=text] {
                box-sizing: border-box;
                border: 0;
                outline: 0;
                color: $search-color;
                border-bottom: 2px solid $search-color;
                text-indent: 3px;
            }

            &::placeholder {
                color: lighten($search-color, 15%);
                opacity: 1;
            }
        }

        button {
           border: none;
           font-size: 0.9em;
           background-color: $brand-color;
           color: $search-color;
        }
    }
}
